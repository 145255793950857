(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  /*var _cookiePolicy = Cookies.get("cookiePolicy");
     if ($(".cookie-policy").length > 0 && _cookiePolicy != "true"){
         $('.cookie-policy').addClass('active');
     }
     $('.cookie-policy .cookie-close').click(function(e){
         e.preventDefault();
         $(this).parents('.cookie-policy').removeClass('active');
         Cookies.set("cookiePolicy", "true", {expires: 999});
     });*/
  //var year = new Date().getFullYear();
  //$('#year').html(year);
}

},{}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  $('.js_phone_mask').mask('500 000 00 00');
  $('.js-letters_only').keyup(function () {
    this.value = this.value.replace(/[^a-zA-ZıİşŞÜüğĞÇçöÖ ]/g, '');
  });
  $.validator.addMethod("validate_email", function (value, element) {
    if (/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value)) {
      return true;
    } else {
      return false;
    }
  });
  $.validator.addMethod("lettersonly", function (value, element) {
    return this.optional(element) || /^[a-zA-Z ıİşŞÜüğĞÇçöÖ]+$/i.test(value);
  });
  $('input#file').change(function () {
    var fullPath = $(this).val();

    if (fullPath) {
      var startIndex = fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/');
      var filename = fullPath.substring(startIndex);

      if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1);
      }

      $('.div-file .file-name').html(filename);
      $('.div-file label.error').remove();
    }
  });
  $("#formValidate").validate({
    ignore: [],
    errorClass: "error",
    errorPlacement: function errorPlacement(error, element) {
      var elementName = element[0].className; //console.log(elementName);

      if (elementName == 'parent-error error') {
        $(element).parent(".form-item").addClass('error-validation');
        error.insertAfter($(element)); //error-msg add.
      } else {
        error.insertAfter($(element)); //error-msg add.
      }
    },
    rules: {
      name: {
        required: true,
        lettersonly: true
      },
      surname: {
        required: true,
        lettersonly: true
      },
      namesurname: {
        required: true,
        lettersonly: true
      },
      email: {
        required: true,
        validate_email: true
      },
      phone: {
        required: true,
        minlength: 13
      },
      message: {
        required: true,
        minlength: 4
      },
      file: {
        required: true,
        extension: "pdf,jpg,jpeg",
        maxsize: 5242880
      }
      /*kvkk : {
      	required: true
      }*/

    },

    /*unhighlight: function (element, errorClass, validClass) {
    	if ($(element).is('select')) {
    		//error.insertAfter($(element).next());
    		$(element).parents('.form-item').removeClass('error-validation');
    	}
    },*/
    submitHandler: function submitHandler(form) {// do other things for a valid form
      //console.log('form submit');
      //console.log('...');
      //formSend(form);
    }
  });
}
/*function formSend(form){
	var formWrapper = $('.form-wrapper');
	var action = $(form).attr("action"),
	method = $(form).attr("method"),
	name   = $(form).attr('name'),
	veri   = $(form).serialize(),
	validate = $(form);
	 //console.log(veri);
	$.ajax({
		type: method,
		url: action,
		data: veri,
		cache:false,
		success:function(data){
			console.log(data);
			//data.success = true;
			//setTimeout(function(){ sessionStorage.clear(); }, 3000);
			if (data==1) {
				console.log('success');
				Swal.fire({
					icon: 'success',
					title: 'Success...',
					text: 'Success message!',
					timer: 1500
				});
				setTimeout(function(){ window.location.reload(); }, 3000);
			}else{
				console.log('error');
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Something went wrong!',
					timer: 1500
				});
				setTimeout(function(){ window.location.reload(); }, 3000);
			}
		}
	});
}*/

},{}],3:[function(require,module,exports){
"use strict";

var _form = _interopRequireDefault(require("./app/form"));

var _cookie = _interopRequireDefault(require("./app/cookie"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

$(document).ready(function () {
  (0, _form["default"])();
  (0, _cookie["default"])();
  $('a[data-fancybox]').fancybox({
    animationEffect: "fade"
  });
  resize();
  $(window).resize(function () {
    resize();
  });
  var swiper = new Swiper('.main-projects .swiper-container', {
    slidesPerView: 3,
    spaceBetween: 0,
    speed: 1000,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      576: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 0
      },
      1200: {
        slidesPerView: 3
      }
    }
  }); //accordion

  var accordionWrapper = $('#accordion-wrapper');
  accordionWrapper.find('.card-link').click(function () {
    var this_all_card = $(this).parent().parent().parent().find('.card').removeClass('active');
    var this_parent_card = $(this).parent().parent().addClass('active');

    if ($(this).parent().next().hasClass('show')) {
      $(this).parent().parent().removeClass('active');
    }
  });
  $('.mobile-toggle-menu a').click(function (e) {
    e.preventDefault();
    $('.accordion-menu').slideToggle();
  }); //offer product add

  var offerButton = $('.offer-button-add');
  offerButton.click(function (e) {
    e.preventDefault();
    var id = $(this).data('id');
    productAddOffer(id);
  }); //offer product delete

  var offerDeleteButton = $('.product-delete');
  offerDeleteButton.click(function (e) {
    e.preventDefault();
    var id = $(this).data('id');
    productRemoveOffer(id);
  });
  var lang = $('html').attr('lang');

  function productAddOffer(sid) {
    var pid = sid;
    var myJSON = "xyz=" + JSON.stringify(pid);
    var xmlhttp = new XMLHttpRequest();

    xmlhttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        var res = JSON.parse(this.responseText);

        if (res.status == 0) {
          Swal.fire({
            icon: 'success',
            title: lang == 'tr' ? 'Başarılı' : 'Success',
            text: res.responseText,
            timer: 1500
          });
        } else if (res.status == 1) {
          Swal.fire({
            icon: 'warning',
            title: lang == 'tr' ? 'Uyarı' : 'Warning',
            text: res.responseText,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: lang == 'tr' ? 'Hata' : 'Error',
            text: res.responseText,
            timer: 1500
          });
        }

        setTimeout(function () {
          window.location.reload(true);
        }, 1500);
      }
    };

    xmlhttp.open("POST", "/panel/ajax/basket.php", true);
    xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xmlhttp.send(myJSON);
  }

  function productRemoveOffer(sid) {
    var pid = sid;
    var myJSON = "xyz=" + JSON.stringify(pid);
    var xmlhttp2 = new XMLHttpRequest();

    xmlhttp2.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        var res = JSON.parse(this.responseText);

        if (res.status == 0) {
          Swal.fire({
            icon: 'success',
            title: lang == 'tr' ? 'Başarılı' : 'Success',
            text: res.responseText,
            timer: 1500
          });
        }

        setTimeout(function () {
          window.location.reload(true);
        }, 1500);
      }
    };

    xmlhttp2.open("POST", "/panel/ajax/basket-delete.php", true);
    xmlhttp2.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xmlhttp2.send(myJSON);
  }

  if ($('#map').length > 0) {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYWdlbmN5bW9vbiIsImEiOiJja2xyM211MXUxMXJrMnVud2RtM29ka2p2In0.jCNNfWIhd_LBhxrMe46imw';
    var map = new mapboxgl.Map({
      container: 'map',
      // container ID
      style: 'mapbox://styles/mapbox/light-v10',
      // style URL
      center: [39.204286, 38.667189],
      // starting position [lng, lat]
      attributionControl: false,
      zoom: 16 // starting zoom

    });
    var marker = new mapboxgl.Marker({
      color: "#056ab3",
      draggable: true
    }).setLngLat([39.204286, 38.667189]).addTo(map);
  }
});

function resize() {
  var windowHeight = $(window).height();
  var mainSlide = $('.main-slider__figure');
  var mainSlide_title = $('.main-slider__title');
  var mainSlideBottom = $('.main-slider__bottom').height();
  mainSlide.height(windowHeight);
  mainSlide_title.height(windowHeight - mainSlideBottom);
}

},{"./app/cookie":1,"./app/form":2}]},{},[3]);
